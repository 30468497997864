import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';

const BasicSelect = ({
  typeSelect,
  stateSelect,
  handleSelect,
  arrayItems,
}) => {
  const gridSelect = {
    gridXs: 12,
    gridMd: 6,
    gridLg: undefined,
  };

  const selectTrackGridStyles = {
    mt: { xs: '1.5rem', md: '0' },
    pl: { xs: '0', md: '0.5rem' },
  };

  const selectUnitGridStyles = {
    display: 'flex',
    alignItems: 'flex-end',
    mt: '1.5rem',
    pr: { xs: 0, md: '0.5rem' },
  };

  const mainObj = {
    driver: {
      gridXs: 12,
      gridMd: 3,
      gridLg: 4,
      styles: selectUnitGridStyles,
      ids: 'select-operador',
      label: 'Operador de unidad',
      optionKey: 'id',
      optionValue: 'nombre',
      sortField: '',
    },
    track: {
      ...gridSelect,
      styles: selectTrackGridStyles,
      ids: 'select-ruta',
      label: 'Ruta a realizar',
      optionKey: 'id',
      optionValue: 'nombre',
      sortField: '',
    },
    unit: {
      ...gridSelect,
      styles: { pr: { xs: '0', md: '0.5rem' } },
      ids: 'select-unidad',
      label: 'Unidad a operar',
      optionKey: 'idUnidad',
      optionValue: 'unidad',
      sortField: 'unidad',
    },
  };

  const {
    gridXs,
    gridMd,
    gridLg,
    styles,
    ids,
    label,
    optionKey,
    optionValue,
    sortField,
  } = mainObj[typeSelect] ?? {};
  if (sortField) arrayItems?.sort((a, b) => (a[sortField] - b[sortField]));
  return (
    <Grid xs={gridXs} md={gridMd} lg={gridLg} sx={{ ...styles }}>
      <FormControl fullWidth size="small">
        <InputLabel id={`${ids}-label`}>{label}</InputLabel>
        <Select
          labelId={`${ids}-label`}
          id={`${ids}-select`}
          value={stateSelect}
          label={label}
          onChange={handleSelect}
          MenuProps={{ PaperProps: { sx: { maxHeight: '25rem' } } }}
        >
          {arrayItems.map((item) => (
            <MenuItem value={item[optionKey]} key={`op-${item[optionKey]}`} sx={{ minHeight: '2.5rem' }}>
              {item[optionValue]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default BasicSelect;

const COLORS = {
  // SLATE
  SLATE50: 'rgb(248, 250, 252)',
  SLATE100: 'rgb(241, 245, 249)',
  SLATE200: 'rgb(226, 232, 240)',
  SLATE300: 'rgb(203, 213, 225)',
  SLATE400: 'rgb(148, 163, 184)',
  SLATE500: 'rgb(100, 116, 139)',
  SLATE600: 'rgb(71, 85, 105)',
  SLATE700: 'rgb(51, 65, 85)',
  SLATE800: 'rgb(30, 41, 59)',
  SLATE900: 'rgb(15, 23, 42)',
  // GRAY
  GRAY50: 'rgb(249, 250, 251)',
  GRAY100: 'rgb(243, 244, 246)',
  GRAY200: 'rgb(229, 231, 235)',
  GRAY300: 'rgb(209, 213, 219)',
  GRAY400: 'rgb(156, 163, 175)',
  GRAY500: 'rgb(107, 114, 128)',
  GRAY600: 'rgb(75, 85, 99)',
  GRAY700: 'rgb(55, 65, 81)',
  GRAY800: 'rgb(31, 41, 55)',
  GRAY900: 'rgb(17, 24, 39)',
  // ZINC
  ZINC50: 'rgb(250, 250, 250)',
  ZINC100: 'rgb(244, 244, 245)',
  ZINC200: 'rgb(228, 228, 231)',
  ZINC300: 'rgb(212, 212, 216)',
  ZINC400: 'rgb(161, 161, 170)',
  ZINC500: 'rgb(113, 113, 122)',
  ZINC600: 'rgb(82, 82, 91)',
  ZINC700: 'rgb(63, 63, 70)',
  ZINC800: 'rgb(39, 39, 42)',
  ZINC900: 'rgb(24, 24, 27)',
  // NEUTRAL
  NEUTRAL50: 'rgb(250, 250, 250)',
  NEUTRAL100: 'rgb(245, 245, 245)',
  NEUTRAL200: 'rgb(229, 229, 229)',
  NEUTRAL300: 'rgb(212, 212, 212)',
  NEUTRAL400: 'rgb(163, 163, 163)',
  NEUTRAL500: 'rgb(115, 115, 115)',
  NEUTRAL600: 'rgb(82, 82, 82)',
  NEUTRAL700: 'rgb(64, 64, 64)',
  NEUTRAL800: 'rgb(38, 38, 38)',
  NEUTRAL900: 'rgb(23, 23, 23)',
  // STONE
  STONE50: 'rgb(250, 250, 249)',
  STONE100: 'rgb(245, 245, 244)',
  STONE200: 'rgb(231, 229, 228)',
  STONE300: 'rgb(214, 211, 209)',
  STONE400: 'rgb(168, 162, 158)',
  STONE500: 'rgb(120, 113, 108)',
  STONE600: 'rgb(87, 83, 78)',
  STONE700: 'rgb(68, 64, 60)',
  STONE800: 'rgb(41, 37, 36)',
  STONE900: 'rgb(28, 25, 23)',
  // RED
  RED50: 'rgb(254, 242, 242)',
  RED100: 'rgb(254, 226, 226)',
  RED200: 'rgb(254, 202, 202)',
  RED300: 'rgb(252, 165, 165)',
  RED400: 'rgb(248, 113, 113)',
  RED500: 'rgb(239, 68, 68)',
  RED600: 'rgb(220, 38, 38)',
  RED700: 'rgb(185, 28, 28)',
  RED800: 'rgb(153, 27, 27)',
  RED900: 'rgb(127, 29, 29)',
  // ORANGE
  ORANGE50: 'rgb(255, 247, 237)',
  ORANGE100: 'rgb(255, 237, 213)',
  ORANGE200: 'rgb(254, 215, 170)',
  ORANGE300: 'rgb(253, 186, 116)',
  ORANGE400: 'rgb(251, 146, 60)',
  ORANGE500: 'rgb(249, 115, 22)',
  ORANGE600: 'rgb(234, 88, 12)',
  ORANGE700: 'rgb(194, 65, 12)',
  ORANGE800: 'rgb(154, 52, 18)',
  ORANGE900: 'rgb(124, 45, 18)',
  // AMBER
  AMBER50: 'rgb(255, 251, 235)',
  AMBER100: 'rgb(254, 243, 199)',
  AMBER200: 'rgb(253, 230, 138)',
  AMBER300: 'rgb(252, 211, 77)',
  AMBER400: 'rgb(251, 191, 36)',
  AMBER500: 'rgb(245, 158, 11)',
  AMBER600: 'rgb(217, 119, 6)',
  AMBER700: 'rgb(180, 83, 9)',
  AMBER800: 'rgb(146, 64, 14)',
  AMBER900: 'rgb(120, 53, 15)',
  // YELLOW
  YELLOW50: 'rgb(254, 252, 232)',
  YELLOW100: 'rgb(254, 249, 195)',
  YELLOW200: 'rgb(254, 240, 138)',
  YELLOW300: 'rgb(253, 224, 71)',
  YELLOW400: 'rgb(250, 204, 21)',
  YELLOW500: 'rgb(234, 179, 8)',
  YELLOW600: 'rgb(202, 138, 4)',
  YELLOW700: 'rgb(161, 98, 7)',
  YELLOW800: 'rgb(133, 77, 14)',
  YELLOW900: 'rgb(113, 63, 18)',
  // LIME
  LIME50: 'rgb(247, 254, 231)',
  LIME100: 'rgb(236, 252, 203)',
  LIME200: 'rgb(217, 249, 157)',
  LIME300: 'rgb(190, 242, 100)',
  LIME400: 'rgb(163, 230, 53)',
  LIME500: 'rgb(132, 204, 22)',
  LIME600: 'rgb(101, 163, 13)',
  LIME700: 'rgb(77, 124, 15)',
  LIME800: 'rgb(63, 98, 18)',
  LIME900: 'rgb(54, 83, 20)',
  // GREEN
  GREEN50: 'rgb(240, 253, 244)',
  GREEN100: 'rgb(220, 252, 231)',
  GREEN200: 'rgb(187, 247, 208)',
  GREEN300: 'rgb(134, 239, 172)',
  GREEN400: 'rgb(74, 222, 128)',
  GREEN500: 'rgb(34, 197, 94)',
  GREEN600: 'rgb(22, 163, 74)',
  GREEN700: 'rgb(21, 128, 61)',
  GREEN800: 'rgb(22, 101, 52)',
  GREEN900: 'rgb(20, 83, 45)',
  // EMERALD
  EMERALD50: 'rgb(236, 253, 245)',
  EMERALD100: 'rgb(209, 250, 229)',
  EMERALD200: 'rgb(167, 243, 208)',
  EMERALD300: 'rgb(110, 231, 183)',
  EMERALD400: 'rgb(52, 211, 153)',
  EMERALD500: 'rgb(16, 185, 129)',
  EMERALD600: 'rgb(5, 150, 105)',
  EMERALD700: 'rgb(4, 120, 87)',
  EMERALD800: 'rgb(6, 95, 70)',
  EMERALD900: 'rgb(6, 78, 59)',
  // TEAL
  TEAL50: 'rgb(240, 253, 250)',
  TEAL100: 'rgb(204, 251, 241)',
  TEAL200: 'rgb(153, 246, 228)',
  TEAL300: 'rgb(94, 234, 212)',
  TEAL400: 'rgb(45, 212, 191)',
  TEAL500: 'rgb(20, 184, 166)',
  TEAL600: 'rgb(13, 148, 136)',
  TEAL700: 'rgb(15, 118, 110)',
  TEAL800: 'rgb(17, 94, 89)',
  TEAL900: 'rgb(19, 78, 74)',
  // CYAN
  CYAN50: 'rgb(236, 254, 255)',
  CYAN100: 'rgb(207, 250, 254)',
  CYAN200: 'rgb(165, 243, 252)',
  CYAN300: 'rgb(103, 232, 249)',
  CYAN400: 'rgb(34, 211, 238)',
  CYAN500: 'rgb(6, 182, 212)',
  CYAN600: 'rgb(8, 145, 178)',
  CYAN700: 'rgb(14, 116, 144)',
  CYAN800: 'rgb(21, 94, 117)',
  CYAN900: 'rgb(22, 78, 99)',
  // SKY
  SKY50: 'rgb(240, 249, 255)',
  SKY100: 'rgb(224, 242, 254)',
  SKY200: 'rgb(186, 230, 253)',
  SKY300: 'rgb(125, 211, 252)',
  SKY400: 'rgb(56, 189, 248)',
  SKY500: 'rgb(14, 165, 233)',
  SKY600: 'rgb(2, 132, 199)',
  SKY700: 'rgb(3, 105, 161)',
  SKY800: 'rgb(7, 89, 133)',
  SKY900: 'rgb(12, 74, 110)',
  // BLUE
  BLUE50: 'rgb(239, 246, 255)',
  BLUE100: 'rgb(219, 234, 254)',
  BLUE200: 'rgb(191, 219, 254)',
  BLUE300: 'rgb(147, 197, 253)',
  BLUE400: 'rgb(96, 165, 250)',
  BLUE500: 'rgb(59, 130, 246)',
  BLUE600: 'rgb(37, 99, 235)',
  BLUE700: 'rgb(29, 78, 216)',
  BLUE800: 'rgb(30, 64, 175)',
  BLUE900: 'rgb(30, 58, 138)',
  // INDIGO
  INDIGO50: 'rgb(238, 242, 255)',
  INDIGO100: 'rgb(224, 231, 255)',
  INDIGO200: 'rgb(199, 210, 254)',
  INDIGO300: 'rgb(165, 180, 252)',
  INDIGO400: 'rgb(129, 140, 248)',
  INDIGO500: 'rgb(99, 102, 241)',
  INDIGO600: 'rgb(79, 70, 229)',
  INDIGO700: 'rgb(67, 56, 202)',
  INDIGO800: 'rgb(55, 48, 163)',
  INDIGO900: 'rgb(49, 46, 129)',
  // VIOLET
  VIOLET50: 'rgb(245, 243, 255)',
  VIOLET100: 'rgb(237, 233, 254)',
  VIOLET200: 'rgb(221, 214, 254)',
  VIOLET300: 'rgb(196, 181, 253)',
  VIOLET400: 'rgb(167, 139, 250)',
  VIOLET500: 'rgb(139, 92, 246)',
  VIOLET600: 'rgb(124, 58, 237)',
  VIOLET700: 'rgb(109, 40, 217)',
  VIOLET800: 'rgb(91, 33, 182)',
  VIOLET900: 'rgb(76, 29, 149)',
  // PURPLE
  PURPLE50: 'rgb(250, 245, 255)',
  PURPLE100: 'rgb(243, 232, 255)',
  PURPLE200: 'rgb(233, 213, 255)',
  PURPLE300: 'rgb(216, 180, 254)',
  PURPLE400: 'rgb(192, 132, 252)',
  PURPLE500: 'rgb(168, 85, 247)',
  PURPLE600: 'rgb(147, 51, 234)',
  PURPLE700: 'rgb(126, 34, 206)',
  PURPLE800: 'rgb(107, 33, 168)',
  PURPLE900: 'rgb(88, 28, 135)',
  // FUCHSIA
  FUCHSIA50: 'rgb(253, 244, 255)',
  FUCHSIA100: 'rgb(250, 232, 255)',
  FUCHSIA200: 'rgb(245, 208, 254)',
  FUCHSIA300: 'rgb(240, 171, 252)',
  FUCHSIA400: 'rgb(232, 121, 249)',
  FUCHSIA500: 'rgb(217, 70, 239)',
  FUCHSIA600: 'rgb(192, 38, 211)',
  FUCHSIA700: 'rgb(162, 28, 175)',
  FUCHSIA800: 'rgb(134, 25, 143)',
  FUCHSIA900: 'rgb(112, 26, 117)',
  // PINK
  PINK50: 'rgb(253, 242, 248)',
  PINK100: 'rgb(252, 231, 243)',
  PINK200: 'rgb(251, 207, 232)',
  PINK300: 'rgb(249, 168, 212)',
  PINK400: 'rgb(244, 114, 182)',
  PINK500: 'rgb(236, 72, 153)',
  PINK600: 'rgb(219, 39, 119)',
  PINK700: 'rgb(190, 24, 93)',
  PINK800: 'rgb(157, 23, 77)',
  PINK900: 'rgb(131, 24, 67)',
  // ROSE
  ROSE50: 'rgb(255, 241, 242)',
  ROSE100: 'rgb(255, 228, 230)',
  ROSE200: 'rgb(254, 205, 211)',
  ROSE300: 'rgb(253, 164, 175)',
  ROSE400: 'rgb(251, 113, 133)',
  ROSE500: 'rgb(244, 63, 94)',
  ROSE600: 'rgb(225, 29, 72)',
  ROSE700: 'rgb(190, 18, 60)',
  ROSE800: 'rgb(159, 18, 57)',
  ROSE900: 'rgb(136, 19, 55)',
};

export default COLORS;

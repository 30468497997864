const SECRETS = {
  // SERVERURL: 'http://127.0.0.1:7000',
  // SERVERURL: 'https://api.caebes.com:8001',
  SERVERURL: 'https://caebes.com:8000',
  // SOCKETURL: 'http://localhost:9001',
  SOCKETURL: 'https://api.caebes.com:9001',
  SOCKETCODE: 'ZPVFCdUg9Vme3QA2dra9a9%wE!Lrd2',
};

export default SECRETS;
